export enum MenuItemKey {
  Assignment = 'assignment',
  StudentWork = 'student-work',
  ParticipatedAssignment = 'participated-assignment',
  // Bottom menu item keys
  Help = 'help',
  Setting = 'setting',
  MarketPlace = 'market-place',
  SignOut = 'sign-out',
}
export * from './routes';
