import { styleVariables, useDebounce } from '@lib/common';
import { getUseCase } from '@lib/plugin-redux-core';
import {
  GetParticipatedAssignmentQueryParams,
  GET_PARTICIPATED_ASSIGNMENT_USE_CASE,
  ParticipatedAssignmentResponder,
} from '@module/assignment';
import {
  EmptySubmissionPlaceholder,
  EmptySubmissionPlaceholderLabel,
  TableParticipatedAssignment,
} from '@module/assignment-components';
import styled from '@xstyled/styled-components';
import { Col, Input, Row, Skeleton } from 'antd';
import { useTranslation } from 'next-i18next';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isParticipatedAssignmentLoadingSelector,
  participatedAssignmentResponseSelector,
} from '../../redux/participated-assignment';
import { routesMap } from '../responder-layout/menu-items/routes';

interface ColumnLabelProps {
  submitTime: string;
  title: string;
  type: string;
  assigner: string;
  score: string;
  idNumber: string;
  totalSubmittedSubmission: string;
  submissionStatus: string;
  clickToViewDetail: string;
}
const StyledParticipatedAssignmentContainer = styled.div`
  height: calc(100vh - 56px);
  padding: 0px 48px 30px 48px;
  background-color: ${styleVariables.colors.gray2};

  overflow: scroll;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
`;
const StyledActionBarAssignment = styled(Row)`
  background-color: ${styleVariables.colors.gray2};
  padding-top: 45px;
  padding-bottom: 24px;
  position: sticky;
  z-index: 10;
  top: 0;
  left: 0;
`;
const StyledListAssignment = styled(Row)`
  .ant-table-wrapper {
    margin-bottom: 20px;
    width: 100%;
    .ant-table-thead > tr > th {
      background: ${styleVariables.colors.gray3};
    }
    .ant-table-thead {
      position: sticky;
      z-index: 10;
      top: 198px;
      left: 0;
    }
  }
`;

const StyledTitle = styled(Col)`
  font-weight: 600;
  font-size: 34px;
  margin-bottom: 18px;
`;

const StyledFilterGroup = styled(Row)`
  margin-bottom: 24px;
`;

const EmptyPlaceholderArea = styled.div`
  height: 100%;
`;

const StyledSearch = styled.div`
  margin-right: 20px;
  .ant-input {
    font-size: 16px;
  }
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled),
  button {
    &:hover,
    &:focus {
      border-color: ${styleVariables.colors.blue4};
    }
  }
  .ant-input-search-button {
    height: 34px;
  }
`;

export const ParticipatedAssignmentContainer = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const handleDebounce = useDebounce(250);

  const listSubmissionsResponse: ParticipatedAssignmentResponder[] = useSelector(
    participatedAssignmentResponseSelector,
  );
  const isLoadingList: boolean = useSelector(isParticipatedAssignmentLoadingSelector);
  // get participated assignments
  const getParticipatedAssignment = useCallback(() => {
    const getParticipatedAssignmentUseCase = getUseCase(
      GET_PARTICIPATED_ASSIGNMENT_USE_CASE,
      dispatch,
    );
    const getParticipatedAssignmentQueryParams: GetParticipatedAssignmentQueryParams = {};
    getParticipatedAssignmentUseCase.execute(getParticipatedAssignmentQueryParams);
  }, [dispatch]);

  useEffect(() => {
    getParticipatedAssignment();
  }, [getParticipatedAssignment]);

  const handleSearch = useCallback(
    (title: string) => {
      handleDebounce(() => {
        const getParticipatedAssignmentUseCase = getUseCase(
          GET_PARTICIPATED_ASSIGNMENT_USE_CASE,
          dispatch,
        );
        const getParticipatedAssignmentQueryParams: GetParticipatedAssignmentQueryParams = {
          search: title,
        };
        getParticipatedAssignmentUseCase.execute(getParticipatedAssignmentQueryParams);
      });
    },
    [dispatch, handleDebounce],
  );

  const emptySubmissionPlaceholderLabel: EmptySubmissionPlaceholderLabel = useMemo(
    () => ({
      title: t('emptySubmission'),
      description: t('letStartANewSubmission'),
    }),
    [t],
  );

  const columnLabels: ColumnLabelProps = {
    submitTime: t('deadline'),
    title: t('title'),
    type: t('type'),
    assigner: t('assigner'),
    score: t('score'),
    idNumber: t('idNumber'),
    totalSubmittedSubmission: t('totalSubmittedSubmission'),
    submissionStatus: t('submissionStatus'),
    clickToViewDetail: t('clickToViewDetail'),
  };
  const navigateToSubmissionDetail = (selectedAssignmentId: string) =>
    window.open(`${routesMap.assignment}/${selectedAssignmentId}`, '_blank');

  const renderContent = (): ReactNode => {
    if (isLoadingList || !listSubmissionsResponse) {
      return (
        <StyledListAssignment>
          <Skeleton paragraph={{ rows: 10 }} />
        </StyledListAssignment>
      );
    }

    const isEmptySubmission = listSubmissionsResponse.length === 0;
    if (isEmptySubmission) {
      return (
        <EmptyPlaceholderArea>
          <EmptySubmissionPlaceholder label={emptySubmissionPlaceholderLabel} />
        </EmptyPlaceholderArea>
      );
    }

    return (
      <StyledListAssignment>
        <TableParticipatedAssignment
          dataSources={listSubmissionsResponse}
          columnLabels={columnLabels}
          navigateToSubmissionDetail={navigateToSubmissionDetail}
        />
      </StyledListAssignment>
    );
  };

  return (
    <StyledParticipatedAssignmentContainer>
      <StyledActionBarAssignment>
        <StyledTitle span={24}>{t('participatedAssignmentList')}</StyledTitle>
        <Col span={24}>
          <StyledFilterGroup>
            <Col span={8}>
              <StyledSearch>
                <Input
                  placeholder={t('searchExamExercise')}
                  allowClear
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </StyledSearch>
            </Col>
          </StyledFilterGroup>
        </Col>
      </StyledActionBarAssignment>
      {renderContent()}
    </StyledParticipatedAssignmentContainer>
  );
};
