import { createContext, useState, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import getConfig from 'next/config';
import { LoadingPage } from '@lib/common';

interface AuthProviderProps {
  children: any;
}

const AuthContext = createContext({
  isAuthenticated: false,
  loading: false,
  logout: () => {},
});
export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { publicRuntimeConfig } = getConfig();
  const router = useRouter();
  const [accessToken, setAccessToken] = useState<string | null | undefined>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadUserFromLocalStorage() {
      const token = localStorage.getItem('access_token');
      if (!token) {
        const addingQueryParams = `&redirectUrl=${window.location.pathname}`;
        const signInPageUrl =
          publicRuntimeConfig.app.authentication.page.signIn.url +
          addingQueryParams;
        router.push(signInPageUrl);
      } else {
        setAccessToken(token);
        setLoading(false);
      }
    }
    loadUserFromLocalStorage();
  }, [publicRuntimeConfig.app.authentication.page.signIn.url, router]);

  const logout = () => {
    localStorage.clear();
    router.push(publicRuntimeConfig.app.authentication.page.signIn.url);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated: !!accessToken, loading, logout }}
    >
      <>{accessToken ? children : <LoadingPage />}</>
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export const ProtectRoute = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();
  if (loading || !isAuthenticated) {
    return <LoadingPage />;
  }
  return children;
};
