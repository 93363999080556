export const routesMap = {
  home: "/",
  assignment: "/assignment",
  studentWork: "/student-work",
  participatedAssignment: "/participated-assignment",
  submission: "/submission",
  //Bottom menu routes mapping
  help: "/help",
  setting: "/setting",
};

export const DEFAULT_PATH = routesMap.participatedAssignment;
